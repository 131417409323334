import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {IMAGECONSTANTS} from '../../../shared/constants/image-constants';
import {DataService} from '../../../core/services/data.service';
import * as _ from 'lodash';
import {MyPets} from '../../../models/my-pets';
import {CONTENT} from '../../../content-management/content';
import {MyPetsService} from '../my-pets.service';
import {PolicyRenewalStatus} from '../../../models/policy-renewal-status';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import {IfStmt} from '@angular/compiler';
import {EncrDecrService} from '../../../core/services/encr-decr.service';
import { FullstoryService } from '../../../shared/services/fullstory.service';

@Component({
  selector: 'app-manage-policy',
  templateUrl: './manage-policy.component.html',
  styleUrls: ['./manage-policy.component.scss']
})
export class ManagePolicyComponent implements OnInit {
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public content = CONTENT;
  public policyNumber: any;
  public managePolicy: MyPets;
  public showCancelPolicy = true;
  public iconObj: any;
  public petSpeciesUrl: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dataService: DataService,
              private myPetService: MyPetsService,
              private encDcrService: EncrDecrService,
              private fullstoryService: FullstoryService,
              private gtmService: GtmEventsService) {
    this.getPolicyFromSession();
    this.getPolicyNumberFromRoutes();
    this.getPolicyInfo();
    this.getSpeciesIcons();
  }

  ngOnInit() {
  }

  getPolicyNumberFromRoutes() {
    this.route.params.subscribe(params => {
      this.policyNumber = this.encDcrService.get(params['id']);
    });
  }

  getPolicyInfo() {
    _.forEach(this.dataService.myPets, pet => {
      if (this.policyNumber === pet.policyNumber) {
        this.managePolicy = pet;
        if (this.managePolicy.policyStatus.toUpperCase() === 'CANCELLED' || this.managePolicy.policyStatus.toUpperCase() === 'EXPIRED' ||
          this.managePolicy.policyStatus === 'Issued but Not Effective') {
          this.showCancelPolicy = false;
        }
      }
    });
  }

  // setCanineImage(policyNumber) {
  //   return _.startsWith(policyNumber, 'C');
  // }

  checkForPetType(policyNumber) 
  {
    let speciesFound = false;
    const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
    const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === policyNumber); 
    // const species = selectedPolicy.pet.species;
    // return species.toUpperCase() === "CANINE";
    switch(selectedPolicy.pet.species.toUpperCase())
    {
      case "CANINE":
        this.petSpeciesUrl = this.iconObj?.canineIcon;
        speciesFound = true;
        break; 
      case "FELINE":
        this.petSpeciesUrl = this.iconObj?.felineIcon;
        speciesFound = true;
        break;
      case "REPTILE":
        this.petSpeciesUrl = this.iconObj?.reptileIcon;
        speciesFound = true;
        break;  
      case "AVIAN":
        this.petSpeciesUrl = this.iconObj?.avianIcon;
        speciesFound = true;
        break; 
      case "EXOTIC":
        this.petSpeciesUrl = this.iconObj?.exoticIcon;
        speciesFound = true;
        break; 
      default:
        this.petSpeciesUrl = this.iconObj?.canineIcon;
        speciesFound = true;
        break;
    }
    return speciesFound;
  }

  getSpeciesIcons() {
    const portalSpeciesIcons = JSON.parse(sessionStorage.getItem('portalSpeciesIcons'));
    if(portalSpeciesIcons) {
      this.filterIcons(portalSpeciesIcons);
    }
  }

  filterIcons(iconList) {
    if(iconList) {
      const canineIcon = iconList?.canineIcon?.fields?.file?.url;
        const felineIcon = iconList?.felineIcon?.fields?.file?.url;
        const exoticIcon = iconList?.exoticIcon?.fields?.file?.url;
        const reptileIcon = iconList?.reptileIcon?.fields?.file?.url;
        const avianIcon = iconList?.avianIcon?.fields?.file?.url;  
      
      this.iconObj = {
        canineIcon : canineIcon,
        felineIcon: felineIcon,
        reptileIcon: reptileIcon,
        avianIcon: avianIcon,
        exoticIcon: exoticIcon       
      }
    }
    
  }

  getPolicyFromSession() {
    this.dataService = JSON.parse(sessionStorage.getItem('dataService'));
  }

  goToPets() {
    this.router.navigate([this.routingConstants.pets]);
  }

  goToRenewal(policyNumber) {
    this.setManagePolicyGtmEvent('Renew Policy', 'Renew Policy Page');
    this.router.navigate([this.routingConstants.renewPolicy, this.encDcrService.set(policyNumber)]);
  }

  goToCancel(policyNumber, renewal) {
    this.setManagePolicyGtmEvent('Cancel Policy', 'Cancel Policy Page');
    this.router.navigate([this.routingConstants.cancelPolicy, this.encDcrService.set(policyNumber), renewal]);
  }

  navigateToViewPolicy() {
    this.myPetService.getPolicyDocumentsSso().subscribe(url => {
      if (url.ssoUrl) {
        if (window.innerWidth <= 768) {
          window.open(url.ssoUrl, '_parent');
        } else {
          window.open(url.ssoUrl, '_blank');
        }
      }
    });
    this.setManagePolicyGtmEvent('View Policy Documents', 'View Policy Documents  Page');
  }

  setManagePolicyGtmEvent(name, navigateTo) {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to Manage Policy',
      eventLabel: name,
      eventValue: navigateTo,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }
}