import { Injectable } from '@angular/core';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FullstoryService {

  private readonly fullStoryOrgId = environment.fullStoryOrgId;
  
  constructor() {
    initFullStory({
      orgId: this.fullStoryOrgId,
      devMode: !environment.production,
    });
  }

  recordCustomEvent(eventName: string, eventProperties: any) {
    FullStory('trackEvent', { name: eventName, properties: eventProperties })
  }
}
